<template>
  <div class="py-3">
    <div class="container">
      <h2>離你最近的舊衣回收箱</h2>

      <div class="border mb-3 py-2 px-3" v-if="!geoStatusSuccess">
        {{ geoStatusMsg }}
      </div>

      <button class="map-btn" @click="openRecycleMap" v-if="geoStatusSuccess">
        <img :src="require('@/assets/share/recycle.png')" />
        <div class="btn-msg">
          <p>有 {{nearByBoxCount}} 個舊衣回收箱</p>
          <span>附近 500 公尺</span>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071Z" fill="#0D0D0D"/>
        </svg>
      </button>

      <div class="notice">
        <p class="title">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8ZM13 17V11H11V17H13Z" fill="#7DC4B7"/>
          </svg>
          可投入舊衣回收箱項目
        </p>
        <p>
          舊衣回收箱主要是推廣二手衣再使用，僅可投入經過清潔整理堪使用的舊衣，例如外衣、外褲、裙子、洋裝、背心、外套、西裝等，均可回收。
        </p>
      </div>

      <h2>品牌回收活動</h2>

      <a v-for="(item, index) in ads" :key="index" class="ad-div" :href="item.link" target="_blank">
        <img :src="item.img" alt="">
        <p>{{ item.name }}</p>
      </a>
    </div>
    
    <Transition name="fade">
      <RecycleMapModal v-if="showRecycleMap" @close="showRecycleMap = false"></RecycleMapModal>
    </Transition>


  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex';
import AD1 from '@/assets/share/ad1.png';
import AD2 from '@/assets/share/ad2.png';
import AD3 from '@/assets/share/ad3.png';
import AD4 from '@/assets/share/ad4.png';
import AD5 from '@/assets/share/ad5.png';

import RecycleMapModal from '@/components/share/RecycleMapModal.vue';

import axios from 'axios';
import { getDistance } from 'geolib';

export default {
  name: 'ShareRecycleMap',
  data() {
    return {
      recyclePoints: [],
      showRecycleMap: false,
      ads: [
        {
          img: AD1,
          name: 'UNIQLO RECYCLE 服裝回收公益活動',
          link: 'https://www.uniqlo.com/tw/collection/sustainability/l3/recycle.html'
        },
        {
          img: AD2,
          name: 'H&M｜Let\'s close the loop 修復與回收',
          link: 'https://www2.hm.com/zh_asia3/sustainability-at-hm/our-work/close-the-loop.html'
        },
        {
          img: AD3,
          name: 'FYNE｜傻瓜計畫',
          link: 'https://www.fyne.life/pages/daft-program'
        },
        {
          img: AD4,
          name: 'LEVI\'S｜❝ 舊愛添新暖•丹寧再造計畫 ❞ 全台指定門市',
          link: 'https://m.facebook.com/nt/screen/?params=%7B%22note_id%22%3A348256869815512%7D&path=%2Fnotes%2Fnote%2F&wtsid=rdr_0yOlUlbMPXU2IDL5P&refsrc=deprecated&_rdr'
        },
        {
          img: AD5,
          name: 'Swedish Stockings｜RECYCLING CLUB',
          link: 'https://swedishstockings.com/pages/recycling-club'
        },
      ],
      geoStatusSuccess: false,
      geoStatusMsg: '正在取得您的位置...',
      nearByBoxCount: 0,
    };
  },
  components: {
    RecycleMapModal,
  },
  computed: {

    },
  watch: {
  },
  async mounted() {
    const busyName = 'GETBOXLIST';
    this.appendComponentBusy(busyName);
    try {
      await this.getRecyclePoints();
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition((position) => {
          let c = 0;
          for (const point of this.recyclePoints) {
            const d = getDistance({latitude: point.lat, longitude: point.lon}, {latitude: position.coords.latitude, longitude: position.coords.longitude});
            if (d < 500) {
              c += 1;
            }
          }
          this.nearByBoxCount = c;
          this.geoStatusSuccess = true;
        }, () => {
          this.geoStatusMsg = '無法取得您的位置資訊，請開啟瀏覽器的定位功能並允許相關權限。';
        });
      } else {
        this.geoStatusMsg = '無法取得您的位置資訊，您的瀏覽器似乎不支援這個功能。';
      }
    } catch (error) {
      console.error(error);
      this.geoStatusMsg = '無法取得您的位置資訊';
    } finally {
      this.clearComponentBusy(busyName);
    }
    
    // console.log(window.navigator);
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    openRecycleMap() {
      this.showRecycleMap = true;
    },
    async getRecyclePoints() {
      const apiUrl = 'https://walkingcloset.network/closet/api/opendata/clothRecycle';

      await axios.get(apiUrl)
        .then(response => {
          this.recyclePoints = response.data.data;
          // console.log(this.recyclePoints)
        })
        .catch(error => {
          console.error('Error fetching recycle points:', error);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .map-btn {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border-radius: 0.5rem;
    border: 1px solid var(--Border-Color-main, #D9D9D9);
    padding: .8rem .8rem;
    column-gap: 1rem;
    margin-bottom: 1rem;
    >img {
      width: 1.5rem;
      display: block;
      flex: 0 0 auto;
    }
    >.btn-msg {
      display: block;
      flex: 1 1;
      text-align: start;
      color: var(--Text-Color-main, #4D4D4D);
      font-size: 1.2rem;
      >p {
        margin-bottom: 0;
        font-weight: 600;
      }
      >span {
        font-size: 1rem;
      }
    }
    >svg {
      display: block;
      flex: 0 0 auto;
    }

  }

  .notice {
    border-radius: 0.5rem;
    background: #d8ede9;
    padding: 1rem .8rem;
    margin-bottom: 1rem;
    p.title {
      color: var(--Text-Color-highlight1, #212429);
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: .5rem;
      >svg {
        vertical-align: text-bottom;
      }
    }
    p {
      color: var(--Text-Color-main, #4D4D4D);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5em;
    }
  }

  .ad-div {
    display: block;
    width: 100%;
    color: var(--Text-Color-main, #4D4D4D);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1.5rem;
    &:hover {
      color: var(--Text-Color-main, #4D4D4D);
      text-decoration: none;
    }
    >img {
      display: block;
      width: 100%;
      margin-bottom: .5rem;
    }
    >p {
      margin: 0;
      display: block;
    
    }
  }
</style>
