<template>
  <FullScreenModal @back="close">
    <div class="map">
      <RealTimeMap></RealTimeMap>
    </div>
  </FullScreenModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import RealTimeMap from '@/components/recycle/RealTimeMap.vue';
import FullScreenModal from '@/components/FullScreenModal.vue';

export default {
  name: 'RecycleMapModal',
  data() {
    return {
    };
  },
  components: {
    RealTimeMap,
    FullScreenModal,
  },
  props: {
  },
  beforeDestroy() {
  },
  async mounted() {

  },
  computed: {
    ...mapState(['clothCategories']),

  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>
  .map {
    height: 100%;
  }

</style>
